import { initContract } from '@ts-rest/core'
import { z } from 'zod'
import { PublicPageResponse } from '../types'
import { ProjectPathParam } from '../types/common'

const c = initContract()

export const tokenDesignerPublicPage = c.router({
  getTDPublicPage: {
    method: 'GET',
    path: `token-designer/public-page/:projectId`,
    pathParams: ProjectPathParam,
    responses: {
      200: PublicPageResponse,
    },
    summary: 'Get token designer public page',
  },
})

export type TokenDesignerPublicPageContractType = typeof tokenDesignerPublicPage
