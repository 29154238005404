import { z } from 'zod'
import {
  DemandDrivers,
  TokenDistributionPreview,
  TokenEmissionPreview,
} from './modeling.types'
import { ProjectDetails } from './valuation.types'
import { GroupUnlockFrequencyEnum } from '@forgd/supabase'

export const EmissionsDetails = z.object({
  name: z.string(),
  allocationPercentage: z.number(),
  unlockType: z.string(),
  unlockAtTGE: z.number(),
  unlockAtInitialCliff: z.number(),
  lockupDurationInMonths: z.number(),
  emissionDuration: z.number(),
  impliedFDV: z.number(),
  unlockFrequency: z
    .nativeEnum(GroupUnlockFrequencyEnum)
    .nullable()
    .default(GroupUnlockFrequencyEnum.Monthly),
})
export type EmissionsDetails = z.infer<typeof EmissionsDetails>

export const LaunchAndGrowthKPIs = z.object({
  avg_annual_inflation: z.number(),
  demand_to_achieve_equilibrium: z.number(),
  header: z.string(),
  pct_of_supply_circulating: z.number(),
  usd_of_tokens_introduced_so_far: z.number(),
})
export type LaunchAndGrowthKPIs = z.infer<typeof LaunchAndGrowthKPIs>

export const PublicPageResponse = z.union([
  z.object({
    distribution: TokenDistributionPreview,
    emissions: TokenEmissionPreview.nullable(),
    emissionsDetails: z.array(EmissionsDetails),
    monthlyIncrease: z.array(
      z.object({
        mon_supply_growth: z.number(),
        month: z.number(),
      }),
    ),
    launchAndGrowthKPIs: z.array(LaunchAndGrowthKPIs),
    demandDriverValues: DemandDrivers,
    estimatedMonthlyDemand: z.array(
      z.object({
        demand_drivers: z.record(z.string(), z.number()),
        months_post_tge: z.number(),
      }),
    ),
    summary: ProjectDetails.nullable(),
  }),
  z.object({ publicPageUrl: z.string() }),
])
export type PublicPageResponse = z.infer<typeof PublicPageResponse>
