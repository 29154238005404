import { z } from 'zod'
import { metaSchema } from '../pagination'
import { MemberInvitationDto } from '../membership'
import {
  AreasOfInterestEnum,
  BaseLayerTypeEnum,
  SectorTypeEnum,
} from '@forgd/supabase'

export const TProject = z.object({
  id: z.string().uuid(),
  hasToken: z.boolean(),
  tokenListed: z.boolean(),
  name: z.string(),
  ticker: z.string().nullable(),
  coingeckoId: z.string().nullable(),
  image: z.string().nullable(),
  link: z.string().nullable(),
  organizationId: z.string().uuid(),
  areasOfInterest: z
    .array(z.nativeEnum(AreasOfInterestEnum))
    .nullable()
    .optional(),
  createdAt: z.date(),
  updatedAt: z.date(),
  deletedAt: z.date(),
  tgeDate: z.date().optional(),
  sector: z.array(z.nativeEnum(SectorTypeEnum)).optional(),
  baseLayer: z.array(z.nativeEnum(BaseLayerTypeEnum)).optional(),
})

export const TUpdateProjectBody = z.object({
  hasToken: z.boolean().optional(),
  tokenListed: z.boolean().optional(),
  name: z.string().trim().min(1).optional(),
  ticker: z
    .string()
    .trim()
    .min(1)
    .transform((val) => val.toUpperCase())
    .optional(),
  coingeckoId: z.string().nullable().optional(),
  image: z.string().url().optional(),
  link: z.string().url().optional(),
  organizationId: z.string().uuid().optional(),
  areasOfInterest: z
    .array(z.nativeEnum(AreasOfInterestEnum))
    .nullable()
    .optional(),
  tgeDate: z
    .string()
    .transform((val) => new Date(val))
    .optional(),
  sector: z.array(z.nativeEnum(SectorTypeEnum)).optional(),
  baseLayer: z.array(z.nativeEnum(BaseLayerTypeEnum)).optional(),
})

const TProjectCreate = TProject.omit({
  id: true,
  coingeckoId: true,
  createdAt: true,
  updatedAt: true,
  deletedAt: true,
})

export const TProjectCreateBody = TProjectCreate.extend({
  members: z.array(MemberInvitationDto).optional(),
})

export const TProjects = z.object({
  items: z.array(TProject),
  meta: metaSchema,
})
