import { initContract } from '@ts-rest/core'
import { TokenomicsContractType, tokenomicsContract } from './tokenomics'
import { Amm2ContractType, amm2Contract } from './amm2'
import {
  AutoDistributionContractType,
  autoDistributionContract,
} from './auto-distribution'
import { ProjectsContractType, projectsContract } from './projects'
import {
  ListingLiquidityContractType,
  listingLiquidityContract,
} from './listing-liquidity'
import {
  BusinessIntelligenceContractType,
  businessIntelligenceContract,
} from './business-intelligence'
import {
  TokenDesignerContractType,
  tokenDesignerContract,
} from './token-designer'
import {
  TokenDesignerValuationContractType,
  tokenDesignerValuationContract,
} from './token-designer.valuation'
import {
  TokenDesignerCalculatorContractType,
  tokenDesignerCalculatorContract,
} from './token-designer.calculator'
import {
  TokenDesignerPublicPageContractType,
  tokenDesignerPublicPage,
} from './token-designer.public'
import { MarketMakerContractType, marketMakerContract } from './market-maker'
import { UsersContractType, usersContract } from './users'
import {
  OrganizationsContractType,
  organizationsContract,
} from './organizations'
import { RaiseCapitalContractType, raiseCapitalContract } from './raise-capital'
import { CoinGeckoContractType, coinGeckoContract } from './coin-gecko'
import { activitiesContract, ActivitiesContractType } from './activities'
import { MembershipContractType, membershipContract } from './membership'
import { onboardingContract, OnboardingType } from './onboarding'

const c = initContract()

type ContractType = {
  activities: ActivitiesContractType
  users: UsersContractType
  organizations: OrganizationsContractType
  projects: ProjectsContractType
  tokenomics: TokenomicsContractType
  autoDistribution: AutoDistributionContractType
  businessIntelligence: BusinessIntelligenceContractType
  amm2: Amm2ContractType
  tokenDesigner: TokenDesignerContractType
  tokenDesignerValuation: TokenDesignerValuationContractType
  tokenDesignerCalculator: TokenDesignerCalculatorContractType
  tokenDesignerPublicPage: TokenDesignerPublicPageContractType
  listingLiquidity: ListingLiquidityContractType
  membership: MembershipContractType
  onboarding: OnboardingType
  coinGecko: CoinGeckoContractType
  raiseCapital: RaiseCapitalContractType
  marketMaker: MarketMakerContractType
}

export const contract: ContractType = c.router({
  activities: activitiesContract,
  users: usersContract,
  organizations: organizationsContract,
  projects: projectsContract,
  tokenomics: tokenomicsContract,
  autoDistribution: autoDistributionContract,
  businessIntelligence: businessIntelligenceContract,
  amm2: amm2Contract,
  tokenDesigner: tokenDesignerContract,
  tokenDesignerValuation: tokenDesignerValuationContract,
  tokenDesignerCalculator: tokenDesignerCalculatorContract,
  tokenDesignerPublicPage: tokenDesignerPublicPage,
  listingLiquidity: listingLiquidityContract,
  membership: membershipContract,
  onboarding: onboardingContract,
  coinGecko: coinGeckoContract,
  raiseCapital: raiseCapitalContract,
  marketMaker: marketMakerContract,
})
