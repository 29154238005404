import unhead_neSs9z3UJp from "/opt/render/project/src/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_VeD7Ba96AP from "/opt/render/project/src/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import supabase_client_RSLEmdgrbx from "/opt/render/project/src/node_modules/@nuxtjs/supabase/dist/runtime/plugins/supabase.client.js";
import payload_client_VTarsGAzgb from "/opt/render/project/src/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_LIYcCMJD18 from "/opt/render/project/src/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_Ib6KnAhqrq from "/opt/render/project/src/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/opt/render/project/src/apps/ui/.nuxt/components.plugin.mjs";
import prefetch_client_8FoqvPFTot from "/opt/render/project/src/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import preview_client_DSw2pvd55k from "/opt/render/project/src/node_modules/@nuxthq/studio/dist/runtime/plugins/preview.client.mjs";
import slideovers_oeUu8xcI5w from "/opt/render/project/src/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.mjs";
import modals_qI5jN0JlD2 from "/opt/render/project/src/node_modules/@nuxt/ui/dist/runtime/plugins/modals.mjs";
import colors_LeAKZ8VmiU from "/opt/render/project/src/node_modules/@nuxt/ui/dist/runtime/plugins/colors.mjs";
import plugin_client_VfWFs4sWWP from "/opt/render/project/src/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.mjs";
import chunk_reload_client_SeG0EjL5Ec from "/opt/render/project/src/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import acheron_client_lqDhrNAz8l from "/opt/render/project/src/apps/ui/core/plugins/acheron.client.js";
import error_logging_client_hb7cDb8LLK from "/opt/render/project/src/apps/ui/core/plugins/error-logging.client.ts";
import feature_flags_client_yDiIZxtHJu from "/opt/render/project/src/apps/ui/core/plugins/feature-flags.client.ts";
import lottie_YCAtSIlAiw from "/opt/render/project/src/apps/ui/core/plugins/lottie.ts";
import primevue_MOhlnAFvlV from "/opt/render/project/src/apps/ui/core/plugins/primevue.js";
import sentry_client_4BqsDhRlQY from "/opt/render/project/src/apps/ui/core/plugins/sentry.client.ts";
export default [
  unhead_neSs9z3UJp,
  router_VeD7Ba96AP,
  supabase_client_RSLEmdgrbx,
  payload_client_VTarsGAzgb,
  check_outdated_build_client_LIYcCMJD18,
  plugin_vue3_Ib6KnAhqrq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_8FoqvPFTot,
  preview_client_DSw2pvd55k,
  slideovers_oeUu8xcI5w,
  modals_qI5jN0JlD2,
  colors_LeAKZ8VmiU,
  plugin_client_VfWFs4sWWP,
  chunk_reload_client_SeG0EjL5Ec,
  acheron_client_lqDhrNAz8l,
  error_logging_client_hb7cDb8LLK,
  feature_flags_client_yDiIZxtHJu,
  lottie_YCAtSIlAiw,
  primevue_MOhlnAFvlV,
  sentry_client_4BqsDhRlQY
]