import { GroupUnlockFrequencyEnum } from '@forgd/supabase'
import { z } from 'zod'

export const DistributionGroup = z.object({
  id: z.string().nullable().default(null),
  name: z.string(),
  allocationPercentage: z.number().min(0).max(100),
  category: z.enum([
    'Core Contributors',
    'Private Sale',
    'Public Sale',
    'Network Incentives',
    'Ecosystem Incentives',
    'Liquidity Provision',
    'Airdrops',
    'Marketing',
    'Treasury',
    'Miscellaneous',
  ]),
  type: z.enum(['internal', 'external']),
  unlockType: z
    .enum(['epoch-based', 'lock-and-vest', 'full-initial-unlock'])
    .default('lock-and-vest'),
  lockupDurationInMonths: z.number().nullable().default(0),
  unlockDurationInMonths: z.number().nullable().default(12),
  unlockFrequency: z
    .nativeEnum(GroupUnlockFrequencyEnum)
    .nullable()
    .default(GroupUnlockFrequencyEnum.Monthly),
  unlockAtInitialCliffPercentage: z.number().nullable().default(0),
  dictateDuration: z.boolean().nullable().default(null),
  delayInMonths: z.number().nullable().default(null),
  durationInMonths: z.number().nullable().default(null),
  epochInMonths: z.number().nullable().default(null),
  firstEpochPercentage: z.number().nullable().default(null),
  reductionsInEpochPercentage: z.number().nullable().default(null),
  fullUnlock: z.boolean().nullable().default(false),
  costBasisTokens: z.number().default(0.0001),
  typeOfSellPressure: z.string().default('aggressive'),
  impliedFDV: z.number().nullable().default(null),
  potentialSourceOfSellPressure: z.boolean().default(true),
  unlockAtTGEPercentage: z.number().min(0).max(100).default(0),
  color: z.optional(z.string()),
})
export const DistributionGroups = z.array(DistributionGroup)
export type DistributionGroup = z.infer<typeof DistributionGroup>
export type DistributionGroups = DistributionGroup[]

export type EmissionGroup = DistributionGroup &
  (LockVestEmissionGroup | EpochBasedEmissionGroup | FullInitialUnlockGroup)

export const LockVestEmissionGroup = z.object({
  name: z.string(),
  lockupDurationInMonths: z.number(),
  unlockDurationInMonths: z.number(),
  unlockFrequency: z.enum(['daily', 'monthly']),
  unlockAtInitialCliffPercentage: z.number(),
})
export type LockVestEmissionGroup = z.infer<typeof LockVestEmissionGroup>

export const EpochBasedEmissionGroup = z.object({
  name: z.string(),
  dictateDuration: z.boolean(),
  delayInMonths: z.number(),
  durationInMonths: z.number().nullable(),
  epochInMonths: z.number(),
  firstEpochPercentage: z.number(),
  reductionsInEpochPercentage: z.number().nullable(),
})
export type EpochBasedEmissionGroup = z.infer<typeof EpochBasedEmissionGroup>

export const FullInitialUnlockGroup = z.object({
  name: z.string(),
  fullUnlock: z.boolean(),
})
export type FullInitialUnlockGroup = z.infer<typeof FullInitialUnlockGroup>

export const BasicCalculation = z.object({
  demandDenomination: z.enum(['usd', 'tokens']),
  estimatedMonthlyDemand: z.number(),
  estimatedMoMGrowthRatePercentage: z.number(),
})
export type BasicCalculation = z.infer<typeof BasicCalculation>

export const BottomUp = z.object({
  startRevenue: z.number(),
  momGrowthPercentage: z.number(),
  revenueDistributedPercentage: z.number(),
  predictionDurationMonths: z.number(),
})
export type BottomUp = z.infer<typeof BottomUp>

export const TopDown = z.object({
  targetRevenue: z.number(),
  growthFactor: z.enum(['conservative', 'moderate', 'aggressive']),
  revenueDistributedPercentage: z.number(),
  projectionDurationMonths: z.number(),
})
export type TopDown = z.infer<typeof TopDown>

export const Comparable = z.object({
  comparableProject: z.string(),
  revenueAttainabilityPercentage: z.number(),
  revenueDistributedPercentage: z.number(),
  predictionDurationMonths: z.number(),
})
export type Comparable = z.infer<typeof Comparable>

export const TokenBuyBacksCalculation = z.object({
  revenueCalculationType: z.enum(['bottom-up', 'top-down', 'comparable']),
  values: z.union([BottomUp, TopDown, Comparable]),
})
export type TokenBuyBacksCalculation = z.infer<typeof TokenBuyBacksCalculation>

export const GovernanceCalculation = z.object({
  comparableProject: z.string(),
  chanceOfVote: z.enum(['low', 'medium', 'high']),
  dropOff: z.enum(['low', 'medium', 'high']),
})
export type GovernanceCalculation = z.infer<typeof GovernanceCalculation>

export const TokenRewards = z.object({
  groupForEmission: z.string(),
  comparableProject: z.string(),
  percentCirculatingSupplyStakedFirstMonth: z.number(),
})
export type TokenRewards = z.infer<typeof TokenRewards>

export const TokenAndRealYield = z.intersection(
  z.object({
    groupForEmission: z.string(),
    comparableProject: z.string(),
    percentCirculatingSupplyStakedFirstMonth: z.number(),
  }),
  z.union([BottomUp, TopDown]),
)

export type TokenAndRealYield = z.infer<typeof TokenAndRealYield>

export const StakingCalculation = z.object({
  typeOfEstimate: z.enum([
    'token-rewards',
    'real-yield',
    'token-and-real-yield',
  ]),
  revenueCalculationType: z.enum(['bottom-up', 'top-down', 'comparable']),
  values: TokenAndRealYield,
})
export type StakingCalculation = z.infer<typeof StakingCalculation>

export const DemandDriverEstimation = z.object({
  id: z.string().nullable(),
  name: z.string(),
  calculationType: z.enum(['basic', 'advanced', 'custom']),
  status: z.enum(['active', 'inactive']),
  calculationTemplate: z.enum([
    'basic',
    'token-buy-backs',
    'governance',
    'staking',
    'locking',
  ]),
  basicCalculation: BasicCalculation.nullable(),
  tokenBuyBacksCalculation: TokenBuyBacksCalculation.nullable(),
  governanceCalculation: GovernanceCalculation.nullable(),
  stakingCalculation: StakingCalculation.nullable(),
})
export type DemandDriverEstimation = z.infer<typeof DemandDriverEstimation>
export const DemandDriverEstimations = z.array(DemandDriverEstimation)
export type DemandDriverEstimations = DemandDriverEstimation[]

export const DemandDriver = z.object({
  id: z.string().nullable(),
  name: z.string(),
  description: z.string(),
  type: z.enum(['utility', 'mechanism']),
  calculationType: z.optional(z.enum(['basic', 'advanced', 'custom'])),
  status: z.optional(z.enum(['active', 'inactive'])),
  calculationTemplate: z.optional(
    z.enum(['basic', 'token-buy-backs', 'governance', 'staking', 'locking']),
  ),
  basicCalculation: z.optional(BasicCalculation.nullable()),
  tokenBuyBacksCalculation: z.optional(TokenBuyBacksCalculation.nullable()),
  governanceCalculation: z.optional(GovernanceCalculation.nullable()),
  stakingCalculation: z.optional(StakingCalculation.nullable()),
})
export type DemandDriver = z.infer<typeof DemandDriver>
export const DemandDrivers = z.array(DemandDriver)
export type DemandDrivers = DemandDriver[]

const modelingSchemaMap = {
  tokendistributionschedule: z.array(DistributionGroup),
  tokenemissionschedule: z.array(DistributionGroup),
  demanddrivers: z.array(DemandDriver),
  estimatingdemand: z.array(DemandDriver),
}

export const ModelingFieldsSchema = z.union([
  z.array(DistributionGroup),
  z.array(DemandDriver),
])
export type ModelingFieldsSchema = z.infer<typeof ModelingFieldsSchema>

const validModelingSubsectionIds = z.union([
  z.literal('tokendistributionschedule'),
  z.literal('tokenemissionschedule'),
  z.literal('demanddrivers'),
  z.literal('estimatingdemand'),
])

export const TokenDistributionPreview = z.object({
  totalAllocation: z.number(),
  totalGroups: z.number(),
  totalInternal: z.number(),
  totalExternal: z.number(),
  allocationSortedByGroup: z.array(
    z.object({
      group: z.string().optional(),
      allocationPercentage: z.number(),
      count: z.number().optional(),
    }),
  ),
  allocationSortedByCategory: z.array(
    z.object({
      group: z.string(),
      allocationPercentage: z.number(),
      count: z.number().optional(),
    }),
  ),
  allocationByType: z.object({
    internal: z.number(),
    external: z.number(),
  }),
  totalAllocationSortedByGroup: z.array(
    z.object({
      group: z.string(),
      allocationPercentage: z.number(),
    }),
  ),
  totalAllocationSortedByCategory: z.array(
    z.object({
      group: z.string(),
      allocationPercentage: z.number(),
    }),
  ),
  totalAllocationByType: z.object({
    internal: z.number(),
    external: z.number(),
  }),
})
export type TokenDistributionPreview = z.infer<typeof TokenDistributionPreview>

const MonthlyAggregationBase = z.record(z.number())
const MonthlyAggregation = MonthlyAggregationBase.and(
  z.object({
    month: z.number(),
  }),
)
export type MonthlyAggregation = z.infer<typeof MonthlyAggregation>
export const TokenEmissionPreview = z.object({
  averageAnnualInflation: z.number(),
  circulatingSupplyAtTGE: z.number(),
  cumulativeEmission: z.array(MonthlyAggregation),
  percentageUnlockedAtTGE: z.number(),
  totalEmissionDuration: z.number(),
  total_groups: z.number(),
})
export type TokenEmissionPreview = z.infer<typeof TokenEmissionPreview>

export const MonthlyDollarDemand = z.array(
  z.object({
    demand_drivers: z.record(z.string(), z.number()),
    months_post_tge: z.number(),
  }),
)
export type MonthlyDollarDemand = z.infer<typeof MonthlyDollarDemand>

export const DemandDetails = z.object({
  Governance: z.optional(
    z.object({
      charts: z.array(
        z.object({
          cumulative_tokens_demand: z.number(),
          months_post_tge: z.number(),
          percentage_of_circulating_supply_participating: z.number(),
          tokens_demand: z.number(),
        }),
      ),
      kpi: z.object({
        average_circ_supply_held_for_governance: z.number(),
        avg_tokens_demanded_per_month: z.number(),
        governance_proposals_per_year: z.number(),
      }),
    }),
  ),
  Staking: z.optional(
    z.object({
      charts: z.array(
        z.object({
          cumulative_monthly_tokens_to_stakers: z.number(),
          cumulative_tokens_demanded: z.number(),
          monthly_revenue_to_stakers: z.number(),
          months_post_tge: z.number(),
          qty_tokens_staked: z.number(),
          tokens_demand: z.number(),
          yield_apr: z.number(),
        }),
      ),
      kpi: z.object({
        average_apr: z.number(),
        average_percent_of_circulating_supply_staked: z.number(),
        avg_tokens_demanded_per_month: z.number(),
      }),
    }),
  ),
  'Token Buybacks': z.optional(
    z.object({
      charts: z.array(
        z.object({
          monthly_percent_of_circ_supply_bought: z.number(),
          monthly_revenue_for_buybacks: z.number(),
          monthly_tokens_bought: z.number(),
          months_post_tge: z.number(),
        }),
      ),
      kpi: z.object({
        avg_monthly_rev_used_for_buybacks: z.number(),
        avg_monthly_tokens_bought: z.number(),
        avg_percent_circulating_supply_purchased_per_month: z.number(),
      }),
    }),
  ),
})
export type DemandDetails = z.infer<typeof DemandDetails>

export const EstimatingDemand = z.object({
  monthlyDollarDemand: MonthlyDollarDemand,
  demandDetails: DemandDetails,
})
export type EstimatingDemand = z.infer<typeof EstimatingDemand>

// Union of all modeling schemas
export const ModelingPreview = z.union([
  TokenDistributionPreview,
  TokenEmissionPreview,
  EstimatingDemand,
])
export type ModelingPreview = z.infer<typeof ModelingPreview>

export const ModelingPreviewResponse = z.array(
  z.object({
    slug: z.string(),
    data: ModelingPreview.nullable(),
  }),
)

export type ModelingPreviewResponse = z.infer<typeof ModelingPreviewResponse>

export const PostModelingBody = z.object({
  subSectionId: z.string(),
  fields: z.unknown(),
})
