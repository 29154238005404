import type { ClientInferResponseBody } from '@ts-rest/core'
import type { contract } from '@forgd/contract'
import { useStorage } from '@vueuse/core'

type Projects = ClientInferResponseBody<
  typeof contract.projects.getProjects,
  200
>
export type User = ClientInferResponseBody<typeof contract.users.me, 200>
export type Organization = Omit<User['organizations'][number], 'projects'>
export type Project = User['organizations'][number]['projects'][number]

type OrganizationWithProjects = Organization & { projects: Project[] }

export const useAuth = defineStore('auth', () => {
  const client = useClient()
  const route = useRoute()

  const loggedIn = ref<boolean | null>(null)
  const me = ref<User | null>(null)
  const organizations = ref<OrganizationWithProjects[] | null>(null)
  const organization = ref<OrganizationWithProjects | null>(null)
  const project = ref<Project | null>(null)
  const pending = ref(false)
  const ticker = computed(() => project.value?.ticker?.toUpperCase())
  const accessTokenCookie = useCookie('forgd-access-token')
  const refreshTokenCookie = useCookie('forgd-refresh-token')
  const authSwrCache = useStorage('forgd:auth:cache', null, import.meta.client ? window.localStorage : undefined, {
    listenToStorageChanges: true,
    serializer: {
      read: (v: any) => v === null ? null : JSON.parse(v),
      write: (v: any) => JSON.stringify(v),
    },
  })

  // watch for auth state changes to sync tabs
  watch(authSwrCache, (val) => {
    if (!val) {
      if (loggedIn.value) {
        logout()
      }
    }
    else if (!loggedIn.value) {
      window.location.href = '/dashboard/'
    }
  })
  const isOrganizationOwner = computed(() => organization.value?.ownerUserId === me.value?.id)

  const shouldAutoLogin = ref(true)
  // if this exists then we're authenticating
  const authCheckPromise: Ref<Promise<boolean> | null> = ref(null)

  async function doAuthFetch() {
    pending.value = true
    const mePayload = await client.users.me().catch((err) => {
      console.error('Auth failed', err)
      return null
    }).finally(() => {
      pending.value = false
    })
    if (mePayload?.status !== 200) {
      if (route.path !== '/login') {
        window.location.href = '/login'
      }
      return false
    }

    loggedIn.value = true
    me.value = mePayload.body
    organizations.value = (me.value.organizations || []) as any as OrganizationWithProjects[]

    let _project = project.value
    let _organization = organization.value
    // we have hydrated project and organization from the cache HOWEVER they may not match the new payload
    // so we select the org and project IF they match, otherwise fallback to the first available active project
    if (_project || _organization) {
      let matched = false
      for (const org of mePayload.body.organizations || []) {
        for (const p of org.projects || []) {
          if (p.id === _project?.id && p.id === _organization?.id) {
            _project = p
            _organization = org
            matched = true
          }
        }
      }
      if (!matched) {
        _project = null
        _organization = null
      }
    }

    // avoid using any stale data for hydration
    organization.value = _organization || organizations.value?.find(organization => organization.memberStatus === 'active') || organizations.value?.[0]
    project.value = _project || organization.value.projects?.[0]
    authSwrCache.value = {
      me: me.value,
      organization: organization.value,
      project: project.value,
    }
    return true
  }

  // opt-in swr hydration of auth payload
  async function check(options?: { swr?: boolean, onFailure?: () => Promise<void> | void }) {
    // avoid multiple auth checks running at once
    if (authCheckPromise.value) {
      return authCheckPromise.value
    }
    return authCheckPromise.value = new Promise<boolean>((resolve) => {
      // we apply SWR logic to authentication
      if (options?.swr && authSwrCache.value) {
        // hydrate from payload
        const { me: _me, organization: _organization, project: _project } = authSwrCache.value
        me.value = _me
        organization.value = _organization
        project.value = _project
        loggedIn.value = true
        // do the auth check async, don't block the user
        doAuthFetch().then((res) => {
          !res && options?.onFailure?.()
        })
        return resolve(true)
      }
      doAuthFetch().then(async (res) => {
        !res && await options?.onFailure?.()
        resolve(res)
      })
    }).finally(() => {
      authCheckPromise.value = null
    })
  }

  /**
   * Note: this needs to be called in addition to the Supabase signOut function
   */
  function logout() {
    loggedIn.value = false
    // full clean up of args to avoid stale data when switching accounts
    me.value = null
    project.value = null
    organization.value = null
    organizations.value = null
    shouldAutoLogin.value = false
    authSwrCache.value = null
    accessTokenCookie.value = null
    refreshTokenCookie.value = null
    refreshCookie('forgd-access-token')
    refreshCookie('forgd-refresh-token')
    navigateTo('/login', { external: true })
  }

  function switchProject(newProject: Projects) {
    project.value = newProject
    authSwrCache.value = {
      me: me.value,
      organizations: organizations.value,
      project: project.value,
    }
  }

  const redirectTo = ref<string | null>(null)

  return {
    organization,
    organizations,
    check,
    switchProject,
    loggedIn,
    me,
    isOrganizationOwner,
    project,
    shouldAutoLogin,
    ticker,
    redirectTo,
    logout,
    pending,
  }
})
