import { initContract } from '@ts-rest/core'
import { z } from 'zod'
import { ProjectPathParam } from '../types/common'

const c = initContract()

const DataPoint = z.tuple([z.number(), z.number()])

export const GetStatsResult = z.object({
  coingeckoId: z.string(),
  price: z.number(),
  change: z.number(),
  volume24h: z.number(),
  spread24h: z.number(),
  depth24h: z.number(),
  marketCap: z.number(),
})

export const GetPriceVsDepthResult = z.object({
  price: z.array(DataPoint),
  depth: z.array(DataPoint),
  summary: z.array(
    z.object({
      price: z.number(),
      oldPrice: z.number(),
      change: z.number(),
      ticker: z.string().nullable(),
      image: z.string().nullable(),
    }),
  ),
})

export const GetDepthChartResult = z.object({
  depthUp: z.array(DataPoint),
  depthDown: z.array(DataPoint),
  summary: z.array(
    z.object({
      depth: z.number(),
      oldDepth: z.number(),
      change: z.number(),
      ticker: z.string().nullable(),
      image: z.string().nullable(),
    }),
  ),
})

export const getVolumeVsSpreadChartResult = z.object({
  volume: z.array(DataPoint),
  spread: z.array(DataPoint),
  summary: z.array(
    z.object({
      spread: z.number(),
      oldSpread: z.number(),
      change: z.number(),
      ticker: z.string().nullable(),
      image: z.string().nullable(),
    }),
  ),
})

export const getVolumeVsPriceChartResult = z.object({
  volume: z.array(DataPoint),
  price: z.array(DataPoint),
  summary: z.array(
    z.object({
      volume: z.number(),
      oldVolume: z.number(),
      change: z.number(),
      ticker: z.string().nullable(),
      image: z.string().nullable(),
    }),
  ),
})

export const getVolumeBreakdownChartResult = z.object({
  lines: z.array(
    z.object({
      name: z.string(),
      volume: z.array(DataPoint),
    }),
  ),
  summary: z.array(
    z.object({
      volume: z.number(),
      oldVolume: z.number(),
      change: z.number(),
      name: z.string(),
    }),
  ),
})

export const getDepthBreakdownChartResult = z.object({
  lines: z.array(
    z.object({
      name: z.string(),
      depth: z.array(DataPoint),
    }),
  ),
  summary: z.array(
    z.object({
      depth: z.number(),
      oldDepth: z.number(),
      change: z.number(),
      name: z.string(),
    }),
  ),
})

export const businessIntelligenceContract = c.router({
  getStats: {
    method: 'GET',
    path: `business-intelligence/stats/:projectId`,
    responses: {
      200: GetStatsResult,
    },
    pathParams: ProjectPathParam,
    summary: 'Get project business intelligence stats',
  },
  getPriceVsDepth: {
    method: 'GET',
    path: `business-intelligence/get-price-vs-depth/:projectId`,
    responses: {
      200: GetPriceVsDepthResult,
    },
    pathParams: ProjectPathParam,
    query: z.object({
      period: z.enum(['24h', '3d', '7d', '30d']),
      type: z.enum(['asks', 'bids', 'all']),
    }),
    summary: 'Get project business intelligence price-vs-depth chart data',
  },
  getDepthChart: {
    method: 'GET',
    path: `business-intelligence/get-depth-chart/:projectId`,
    responses: {
      200: GetDepthChartResult,
    },
    pathParams: ProjectPathParam,
    query: z.object({
      period: z.enum(['24h', '3d', '7d', '30d']),
    }),
    summary: 'Get project business intelligence depth chart data',
  },
  getVolumeVsSpreadChart: {
    method: 'GET',
    path: `business-intelligence/get-spread-vs-volume-chart/:projectId`,
    responses: {
      200: getVolumeVsSpreadChartResult,
    },
    pathParams: ProjectPathParam,
    query: z.object({
      period: z.enum(['30d']),
    }),
    summary: 'Get project business intelligence volume vs spread chart data',
  },
  getVolumeVsPriceChart: {
    method: 'GET',
    path: `business-intelligence/get-volume-vs-price-chart/:projectId`,
    responses: {
      200: getVolumeVsPriceChartResult,
    },
    pathParams: ProjectPathParam,
    query: z.object({
      period: z.enum(['30d']),
    }),
    summary: 'Get project business intelligence volume vs price chart data',
  },
  getVolumeBreakdownChart: {
    method: 'GET',
    path: `business-intelligence/get-volume-breakdown-chart/:projectId`,
    responses: {
      200: getVolumeBreakdownChartResult,
    },
    pathParams: ProjectPathParam,
    query: z.object({
      period: z.enum(['30d']),
    }),
    summary: 'Get project business intelligence volume breakdown chart data',
  },
  getDepthBreakdownChart: {
    method: 'GET',
    path: `business-intelligence/get-depth-breakdown-chart/:projectId`,
    responses: {
      200: getDepthBreakdownChartResult,
    },
    pathParams: ProjectPathParam,
    query: z.object({
      period: z.enum(['24h', '3d', '7d', '30d']),
    }),
    summary: 'Get project business intelligence depth breakdown chart data',
  },
})

export type BusinessIntelligenceContractType =
  typeof businessIntelligenceContract
