import { initContract } from '@ts-rest/core'
import { z } from 'zod'
import {
  GetActiveMarketMakerDetailsQuery,
  GetActiveMarketMakerDetailsResult,
  PostMarketMakerResult,
  PostMarketMakerSchema,
} from '../types'
import { ProjectPathParam } from '../types/common'

const c = initContract()

export const marketMakerContract = c.router({
  postMarketMaker: {
    method: 'POST',
    path: `market-maker/:projectId`,
    responses: {
      201: PostMarketMakerResult,
    },
    body: PostMarketMakerSchema,
    pathParams: ProjectPathParam,
    summary: 'Create market maker request',
  },
  getMarketMakerByProjectId: {
    method: 'GET',
    path: `market-maker/:projectId`,
    responses: {
      200: PostMarketMakerResult,
    },
    pathParams: ProjectPathParam,
    summary: 'Get market maker by project id',
  },
  getActiveMarketMakerDetails: {
    method: 'GET',
    path: `market-maker/details/:projectId`,
    responses: {
      200: GetActiveMarketMakerDetailsResult,
      204: z.undefined(),
    },
    pathParams: ProjectPathParam,
    query: GetActiveMarketMakerDetailsQuery,
    summary: 'Get market maker details',
  },
})

export type MarketMakerContractType = typeof marketMakerContract
