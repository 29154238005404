import { initContract } from '@ts-rest/core'
import { z } from 'zod'

import {
  PriceVsMarketCapSchema,
  PriceVsMarketCapResultSchema,
  PriceChangeMCFDVResult,
  ValueInCirculationResultSchema,
  SummaryResult,
  TCirculatingSupplyBody,
  TVestingResult,
  TokenAllocationResult,
  TokenAllocationSchema,
  CapitalizationRecord,
  SellPressureSchema,
  SellPressureUSDResultSchema,
  PostTokenomicsSchema,
  PostTokenomicsResult,
  ValueInCirculationSchema,
  DownloadFileResponse,
} from '../types'
import { ProjectPathParam } from '../types/common'

const c = initContract()

export const tokenomicsContract = c.router({
  getTokenomicsTemplates: {
    method: 'GET',
    path: `tokenomics/templates`,
    responses: {
      200: z.record(z.array(CapitalizationRecord)),
    },
    summary: 'Get tokenomics templates',
  },
  getTokenomicsCirculatingSupplyPreview: {
    method: 'POST',
    path: `tokenomics/circulating-supply/preview`,
    responses: {
      200: TVestingResult,
    },
    body: TCirculatingSupplyBody,
    summary: 'Get tokenomics circulating supply preview',
  },
  getTokenomicsAllocationPreview: {
    method: 'POST',
    path: `tokenomics/token-allocation/preview`,
    responses: {
      200: TokenAllocationResult,
    },
    body: TokenAllocationSchema,
    summary: 'Get tokenomics allocation preview',
  },
  getTokenomicsValueCirculationPreview: {
    method: 'POST',
    path: `tokenomics/value-circulation/preview`,
    responses: {
      200: ValueInCirculationResultSchema,
    },
    body: ValueInCirculationSchema,
    summary:
      'Get tokenomics value in circulation vs. cost basis over time preview',
  },
  getTokenomicsSellPressurePreview: {
    method: 'POST',
    path: `tokenomics/sell-pressure/preview`,
    responses: {
      200: SellPressureUSDResultSchema,
    },
    body: SellPressureSchema,
    summary: 'Get tokenomics sell pressure preview',
  },
  getPriceVsMarketCapPreview: {
    method: 'POST',
    path: `tokenomics/price-vs-market-cap/preview`,
    responses: {
      200: PriceVsMarketCapResultSchema,
    },
    body: PriceVsMarketCapSchema,
    summary: 'Get tokenomics price vs. market cap preview',
  },
  getPriceChangeVsMCFDVRatioPreview: {
    method: 'POST',
    path: `tokenomics/price-change-vs-mcfdv-ratio/preview`,
    responses: {
      200: PriceChangeMCFDVResult,
    },
    body: SellPressureSchema,
    summary: 'Get tokenomics price change vs. mcfdv ratio preview',
  },
  getTokenomicsSummaryPreview: {
    method: 'POST',
    path: `tokenomics/summary/preview`,
    responses: {
      200: SummaryResult,
    },
    body: PriceVsMarketCapSchema,
    summary: 'Get tokenomics summary',
  },
  postTokenomics: {
    method: 'POST',
    path: `tokenomics`,
    responses: {
      201: PostTokenomicsResult,
    },
    body: PostTokenomicsSchema,
    summary: 'Create tokenomics',
  },
  getTokenomics: {
    method: 'GET',
    path: `tokenomics/:projectId`,
    responses: {
      200: z.array(PostTokenomicsResult),
    },
    pathParams: ProjectPathParam,
    summary: 'Get tokenomics',
  },
  getTokenomicsById: {
    method: 'GET',
    path: `tokenomics/:id`,
    responses: {
      200: PostTokenomicsResult,
    },
    pathParams: z.object({
      id: z.string().uuid(),
    }),
    summary: 'Get tokenomics by id',
  },
  downloadTokenomicsById: {
    method: 'GET',
    path: `tokenomics/:projectId/download`,
    responses: {
      200: DownloadFileResponse,
    },
    pathParams: ProjectPathParam,
    summary: 'Download tokenomics by id',
  },
})

export type TokenomicsContractType = typeof tokenomicsContract
