import { initContract } from '@ts-rest/core'
import { z } from 'zod'
import { PostRaiseCapitalSchema, PostRaiseCapitalResult } from '../types'
import { ProjectPathParam } from '../types/common'

const c = initContract()

export const raiseCapitalContract = c.router({
  postRaiseCapital: {
    method: 'POST',
    path: `raise-capital/:projectId`,
    responses: {
      201: PostRaiseCapitalResult,
    },
    pathParams: ProjectPathParam,
    body: PostRaiseCapitalSchema,
    summary: 'Create raise capital request',
  },
  getRaiseCapitalByProjectId: {
    method: 'GET',
    path: `raise-capital/:projectId`,
    responses: {
      200: PostRaiseCapitalResult,
    },
    pathParams: ProjectPathParam,
    summary: 'Get raise capital by project id',
  },
})

export type RaiseCapitalContractType = typeof raiseCapitalContract
